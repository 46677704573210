<template>
  <v-container
    id="client"
    fluid
    tag="section"
    style="height: calc(100% - 90px);"
    class="container-full-size"
  >
    <v-col
      cols="12"
    >
      <v-btn
        color="default"
        class="mr-0"
        @click="back()"
      >
        {{ str['back'] }}
      </v-btn>
      <v-btn
        v-if="showChangeClient && (changeClientIndex === null || (changeClientIndex !== null && changeClientIndex > 0))"
        color="blue darken-3"
        class="mr-0"
        style="margin-left: 10px;"
        @click="previousClient()"
      >
        {{ str['previous_back'] }}
      </v-btn>
      <v-btn
        v-if="showChangeClient && (changeClientIndex === null || (changeClientIndex !== null && changeClientIndex < changeClientList.length - 1))"
        color="blue darken-3"
        class="mr-0"
        style="margin-left: 10px;"
        @click="nextClient()"
      >
        {{ str['next'] }}
      </v-btn>
    </v-col>
    <template>
      <v-tabs
        v-model="tab"
        background-color="transparent"
        color="secondary"
        style="margin-top: 0;"
        grow
        show-arrows
      >
        <v-tab
          v-for="item in tabs"
          :key="item.id"
        >
          {{ item.title }}
        </v-tab>
      </v-tabs>

      <v-tabs-items
        v-model="tab"
        style="min-height: calc(100% - 115px);"
      >
        <v-tab-item
          v-for="item in tabs"
          :key="item.id"
        >
          <div
            v-if="item.id === 'details'"
          >
            <client-details
              ref="details"
              :parent="scope"
              :client="client"
            />
          </div>
          <div
            v-if="item.id === 'physical_evaluation'"
          >
            <client-physical-evaluations
              ref="physical_evaluation"
              :parent="scope"
              :client="client"
            />
          </div>
          <div
            v-if="item.id === 'training_plan'"
          >
            <client-training-plan
              ref="training_plan"
              :parent="scope"
              :client="client"
            />
          </div>
          <div
            v-if="item.id === 'plans'"
          >
            <client-plans
              ref="plan"
              :parent="scope"
              :client="client"
            />
          </div>
          <div
            v-if="item.id === 'trains'"
          >
            <client-trains
              ref="trains"
              :parent="scope"
              :client="client"
            />
          </div>
          <div
            v-if="item.id === 'food_plan'"
          >
            <client-food-plan
              ref="food_plan"
              :parent="scope"
              :client="client"
            />
          </div>
          <div
            v-if="item.id === 'nutrition_plan'"
          >
            <client-nutrition-plan
              ref="nutrition_plan"
              :parent="scope"
              :client="client"
            />
          </div>
          <div
            v-if="item.id === 'diets'"
          >
            <client-diets
              ref="diets"
              :parent="scope"
              :client="client"
            />
          </div>
          <div
            v-if="item.id === 'payments'"
          >
            <client-payments
              ref="payments"
              :parent="scope"
              :client="client"
            />
          </div>
          <div
            v-if="item.id === 'notifications'"
          >
            <client-notifications
              ref="notifications"
              :parent="scope"
              :client="client"
            />
          </div>
          <div
            v-if="item.id === 'calendar'"
          >
            <client-calendar
              ref="calendar"
              :parent="scope"
              :client="client"
            />
          </div>
        </v-tab-item>
      </v-tabs-items>
    </template>
  </v-container>
</template>

<script>
  import Api from '@/services/Api'
  import Utils from '@/services/Utils'

  export default {
    components: {
      ClientDetails: () => import('@/views/dashboard/components/client/Details'),
      ClientPhysicalEvaluations: () => import('@/views/dashboard/components/client/PhysicalEvaluations'),
      ClientTrainingPlan: () => import('@/views/dashboard/components/client/TrainingPlan'),
      ClientPlans: () => import('@/views/dashboard/components/client/Plans'),
      ClientTrains: () => import('@/views/dashboard/components/client/Trains'),
      ClientFoodPlan: () => import('@/views/dashboard/components/client/FoodPlan'),
      ClientNutritionPlan: () => import('@/views/dashboard/components/client/NutritionPlan'),
      ClientDiets: () => import('@/views/dashboard/components/client/Diets'),
      ClientPayments: () => import('@/views/dashboard/components/client/Payments'),
      ClientNotifications: () => import('@/views/dashboard/components/client/Notifications'),
      ClientCalendar: () => import('@/views/dashboard/components/client/Calendar'),
    },
    data() {
      const storageClient = Utils.getStorage('client')
      const client = storageClient ? storageClient : {}
      const user = Utils.getUser()

      let showPtNotes = false
      if (user && user.configurations && user.configurations.clients && user.configurations.clients.show_notes) {
        showPtNotes = true
      }

      let showChangeClient = false
      let changeClientIndex = null
      let changeClientList = null
      try {
        if (window.clientPageCacheList && user && user.configurations && user.configurations.clients && user.configurations.clients.show_change_client) {
          showChangeClient = true
          if (window.clientPageCacheList.queryNextClient && window.clientPageCacheList.queryPreviousClient) {
            changeClientList = window.clientPageCacheList
          } else {
            changeClientList = JSON.parse(JSON.stringify(window.clientPageCacheList))
            if (changeClientList && changeClientList.length) {
              changeClientIndex = changeClientList.findIndex(function (cl) {
                return cl.id === client.id
              })  
            }
          }
        }
      } catch { }

      return {
        scope: this,
        str: window.strings,
        client: client,
        user: user,
        showPtNotes: showPtNotes,
        showChangeClient: showChangeClient,
        changeClientList: changeClientList,
        changeClientIndex: changeClientIndex,
        tab: 0,
        tabs: [],
        ptNotes: null,
      }
    },
    watch: {
      tab: function () {
        Utils.setStorage('clientTab', this.tab)
      },
    },
    beforeMount: function () {
      if (!this.user) {
        return false
      }
      this.main()
    },
    methods: {
      back: function () {
        this.keepClientsFilters = true
        this.$router.back()
      },
      main: function () {
        Utils.removeRequestCache('last_physical_evaluation')
        this.setTabs()
        this.setPtNotes()
      },
      setTabs: function () {
        const defaultTabs = [{
          id: 'details',
          title: window.strings['general_details'],
        }, {
          id: 'physical_evaluation',
          title: window.strings['physical_evaluation'],
        }, {
          id: 'training_plan',
          title: window.strings['training_plan'],
        }, {
          id: 'plans',
          title: window.strings['plans'],
        }, {
          id: 'trains',
          title: window.strings['training_plan'],
        }, {
          id: 'nutrition_plan',
          title: this.user && this.user.configurations && this.user.configurations.nutrition && this.user.configurations.nutrition.tab_name ? this.user.configurations.nutrition.tab_name : window.strings['nutrition_plan'],
        }, {
          id: 'diets',
          title: window.strings['diet_and_supplementation'],
        }, {
          id: 'food_plan',
          title: window.strings['nutrition_plan'],
        }, {
          id: 'payments',
          title: window.strings['payments'],
        }, {
          id: 'notifications',
          title: window.strings['notifications'],
        }, {
          id: 'calendar',
          title: window.strings['calendar'],
        }]
        const tabs = []

        for (let i = 0; i < defaultTabs.length; i++) {
          if (this.user && this.user.configurations && this.user.configurations.client &&
            this.user.configurations.client.indexOf(defaultTabs[i].id) > -1 &&
            Utils.hasPermission('client', defaultTabs[i].id) &&
            this.tabControlValidated(defaultTabs[i].id)
          ) {
            tabs.push(defaultTabs[i])
          }
        }

        this.tabs = tabs
        this.setInitialTab()
      },
      tabControlValidated: function (id) {
        const tabsControl = this.user && this.user.configurations && this.user.configurations.clients && this.user.configurations.clients.show_tabs_control ? this.user.configurations.clients.show_tabs_control : null
        if (tabsControl && tabsControl[id] && tabsControl[id].hide) {
          for (let i = 0; i < tabsControl[id].hide.length; i++) {
            const item = tabsControl[id].hide[i]
            if (item.field && item.condition && item.value) {
              switch (item.condition) {
                case 'equal':
                  if (this.client[item.field] === item.value) {
                    return false
                  }
                  break
                case 'higher':
                  if (this.client[item.field] > item.value) {
                    return false
                  }
                  break
                case 'lower':
                  if (this.client[item.field] < item.value) {
                    return false
                  }
                  break
              }
            }
          }
        }
        return true
      },
      setInitialTab: function () {
        const cacheClientTab = Utils.getStorage('clientTab')
        let initialTab = cacheClientTab ? parseInt(cacheClientTab) : 0

        if (this.$route.query && this.$route.query.tab !== undefined) {
          const tabParameter = this.$route.query.tab
          initialTab = parseInt(tabParameter)

          if (isNaN(initialTab)) {
            const tabIndex = this.tabs.findIndex(function (t) {
              return t.id === tabParameter
            })
            if (tabIndex !== undefined) {
              initialTab = tabIndex
            }
          }

          const url = new URL(window.location.href)
          url.hash = url.hash.replace(/([&?])tab=.*?(&|$)/, '$1').replace(/(^[?&])|(&$)/g, '')
          history.replaceState(null, null, url.href)
        }

        this.tab = initialTab
      },
      setPtNotes: function () {
        const ptNotes = {
          physical_evaluation: '',
          training_plan: '',
          food_plan: '',
          details_notes: '',
        }

        if (this.showPtNotes) {
          try {
            if (this.client.microcycle) {
              const microcycle = JSON.parse(this.client.microcycle)

              if (microcycle) {
                if (microcycle.physical_evaluation) {
                  ptNotes.physical_evaluation = microcycle.physical_evaluation
                }
                if (microcycle.training_plan) {
                  ptNotes.training_plan = microcycle.training_plan
                }
                if (microcycle.food_plan) {
                  ptNotes.food_plan = microcycle.food_plan
                }
                if (microcycle.details_notes) {
                  ptNotes.details_notes = microcycle.details_notes
                }
                if (microcycle.evolution_photos_permission_requested) {
                  ptNotes.evolution_photos_permission_requested = microcycle.evolution_photos_permission_requested
                }
              }
            }
          } catch { }
        }

        this.ptNotes = ptNotes
      },
      savePtNotes: function () {
        const self = this
        Api.updateUser({
          id: this.client.id,
          microcycle: JSON.stringify(this.ptNotes),
        }, function (response) {
          if (response.success) {
            self.client.microcycle = response.data.microcycle
            Utils.setStorage('client', self.client)
            self.$alert(
              window.strings['notes_saved'],
              '',
              'success',
              Utils.getAlertOptions()
            )
          } else {
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      getPlanStatus: function (plan, type, plans) {
        let result = {
          type: 'default',
          label: window.strings['n_a'],
        }
        const keysValues = {}
        const keysStatus = {}
        Utils.getPlanStatus().forEach(function (item) {
          keysValues[item.value] = item
          keysStatus[item.status] = item
        })
        if (this.user && this.user.configurations[type] && this.user.configurations[type].only_one_active && keysValues[plan.status] && keysValues[plan.status].status === 'active') {
          if (plans && plans[0].id !== plan.id) {
            return keysStatus['finished']
          }
        }
        if (keysValues[plan.status]) {
          result = keysValues[plan.status]
        }
        return result
      },
      refresh: function (tab) {
        switch (tab) {
          case 'details':
            if (this.$refs.details && this.$refs.details[0]) {
              this.$refs.details[0].refresh()
            }
            break;
          case 'physical_evaluation':
            if (this.$refs.physical_evaluation && this.$refs.physical_evaluation[0]) {
              this.$refs.physical_evaluation[0].refresh()
            }
            break;
          case 'payments':
            if (this.$refs.payments && this.$refs.payments[0]) {
              this.$refs.payments[0].refresh()
            }
            break;
        }
      },
      previousClient: function () {
        if (this.changeClientList && this.changeClientList.queryPreviousClient) {
          this.openClientWithQuery(this.changeClientList.queryPreviousClient)
        } else {
          const index = this.changeClientIndex - 1
          if (this.changeClientList[index]) {
            this.openClient(this.changeClientList[index])
          }
        }
      },
      nextClient: function () {
        if (this.changeClientList && this.changeClientList.queryNextClient) {
          this.openClientWithQuery(this.changeClientList.queryNextClient)
        } else {
          const index = this.changeClientIndex + 1
          if (this.changeClientList[index]) {
            this.openClient(this.changeClientList[index])
          }
        }
      },
      openClientWithQuery: function (query) {
        const self = this
        this.$isLoading(true)
        Api.getClientsWithQuery({
          query: query.replace('<client_id>', this.client.id),
          orderDisabled: true,
          compressDisabled: true
        }, function (response) {
          self.$isLoading(false)
          if (response.success) {
            if (response.data && response.data[0]) {
              const client = Utils.encodeClient(response.data[0])
              Utils.setStorage('client', client)
              self.keepClientsFilters = true
              self.$router.replace({ path: '/home/empty', query: { tab: 0 } })
              setTimeout(function () {
                self.$router.replace({ path: '/home/client', query: { tab: 'details' } })
              }, 500)
            } else {
              self.$alert(
                window.strings['prev_next_client_not_found'],
                '',
                'warning',
                Utils.getAlertOptions()
              )
            }
          } else {
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      openClient: function (client) {
        const self = this
        this.$isLoading(true)
        Api.getUserWithId({
          id: client.id
        }, function (response) {
          self.$isLoading(false)
          if (response.success) {
            const client = Utils.encodeClient(response.data[0])
            Utils.setStorage('client', client)
            self.keepClientsFilters = true
            self.$router.replace({ path: '/home/empty', query: { tab: 0 } })
            setTimeout(function () {
              window.clientPageCacheList = JSON.parse(JSON.stringify(self.changeClientList))
              self.$router.replace({ path: '/home/client', query: { tab: 'details' } })
            }, 500)
          } else {
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
    },
    beforeDestroy: function () {
      if (!this.keepClientsFilters && window.cacheTables && window.cacheTables.clients) {
        delete window.cacheTables.clients
      }
    },
  }
</script>
